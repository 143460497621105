import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

// Content Components
import PageElements from '../../elements/PageElements'

// Generic
import ArticleGrid from '../../shared/ArticleGrid/ArticleGrid'
import ArticleGridFeaturedItem from '../../shared/ArticleGrid/ArticleGridFeaturedItem'
import Image from '../../shared/Image/Image'
import Pagination from '../../shared/Pagination/Pagination'

// Local
import BlogArticleIndexHero from '../../shared/BlogArticle/BlogArticleIndexHero'
import BlogArticleIndexContent from '../../shared/BlogArticle/BlogArticleIndexContent'
import BlogArticleIndexContentHeader from '../../shared/BlogArticle/BlogArticleIndexContentHeader'
import BlogArticleIndexCategorySelect from '../../shared/BlogArticle/BlogArticleIndexCategorySelect'

// Utils
import { urlMap } from '../../../utils/urls'
import blogArticleDecorator from '../../../utils/blogArticleDecorator'

const BlogArticleIndex = ({ data, pageContext, location }) => {
  const [formIsLoading, setFormIsLoading] = useState(false)

  const handleSearchFormSubmit = (event) => {
    event.preventDefault()

    setFormIsLoading(true)
    // Get the value from the form
    const searchQuery = new window.FormData(event.target).get('q')

    // Navgiate to the search page, take the state too
    navigate(`${urlMap.blog_article}search#content`, {
      state: { searchQuery }
    })
  }

  /**
   * Get the title of the content section based on the current category
   * @return {String}
   */
  const getContentTitle = () => {
    // This is 'all' posts
    if (pageContext.categoryUid === '*') {
      return 'Latest updates'
    }

    return data.blogCategories.nodes.find((blogCategoryNode) => {
      return blogCategoryNode.uid === pageContext.categoryUid
    }).data.title.text
  }

  return (
    <>
      <BlogArticleIndexHero
        title={data.page.data.title.text}
        body='The latest news and insights on coding and the tech industry'
        handleSubmit={handleSearchFormSubmit}
        formIsLoading={formIsLoading}
        location={location}
      />
      <BlogArticleIndexContent>
        <BlogArticleIndexContentHeader>
          <h2>{getContentTitle()}</h2>
          <BlogArticleIndexCategorySelect
            categories={data.blogCategories.nodes}
            currentCategory={pageContext.categoryUid}
            taxonomy='blog_article'
            categoryTaxonomy='blog_category'
          />
        </BlogArticleIndexContentHeader>
        <div className='mb-16'>
          {(data.featuredBlogArticles.nodes.length > 0) && (
            <ArticleGridFeaturedItem
              body={data.featuredBlogArticles.nodes[0].data.intro.text}
              category='Featured'
              className='mb-6'
              media={(
                <Image
                  className='h-full object-cover'
                  fallbackAlt={data.featuredBlogArticles.nodes[0].data.title.text}
                  {...data.featuredBlogArticles.nodes[0].data.featured_image}
                />
              )}
              title={data.featuredBlogArticles.nodes[0].data.title.text}
              url={data.featuredBlogArticles.nodes[0].url}
            />
          )}
          <ArticleGrid
            articles={blogArticleDecorator(data.blogArticles.nodes)}
          />
        </div>
        <Pagination
          taxonomy={pageContext.categoryUid !== '*' ? 'blog_category' : 'blog_article'}
          totalCount={data.blogArticles.totalCount}
          pageContext={pageContext}
        />
      </BlogArticleIndexContent>
      <PageElements slices={data.page.data.body} />
    </>
  )
}

BlogArticleIndex.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default BlogArticleIndex
