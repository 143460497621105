import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

// Utils
import { urlMap } from '../../../utils/urls'

const BlogArticleIndexCategorySelect = (props) => {
  const { categories, currentCategory, taxonomy, categoryTaxonomy } = props
  /**
   * Go to the category page when the filter changes
   * @param  {Object} event
   * @return {Void}
   */
  const handleChange = (event) => {
    navigate(`${event.target.value}#content`)
  }

  return (
    <form className='c-form'>
      <select
        className='c-form__input c-form__input--select text-black'
        defaultValue={currentCategory === '*' ? urlMap[taxonomy] : `${urlMap[categoryTaxonomy]}${currentCategory}`}
        onChange={handleChange}
      >
        <option
          value={urlMap[taxonomy]}
        >
          {currentCategory === '*' ? 'Filter by category' : 'View all'}
        </option>
        {categories.map((category) => {
          return (
            <option
              key={category.id}
              value={category.url}
            >
              {category.data.title.text}
            </option>
          )
        })}
      </select>
    </form>
  )
}

BlogArticleIndexCategorySelect.propTypes = {
  categories: PropTypes.array.isRequired,
  currentCategory: PropTypes.string.isRequired,
  taxonomy: PropTypes.string.isRequired,
  categoryTaxonomy: PropTypes.string.isRequired
}

export default BlogArticleIndexCategorySelect
